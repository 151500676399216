import React from 'react';
import {Container, Row, Col, Jumbotron} from 'react-bootstrap';

export default function Business() {
    return (
        <Container className="BusinessArea">
            <Row>
                <Col xs="12" sm="6">
                    <Jumbotron fluid className="Business-Content first">
                        <Container className="right-border">
                            <h1><span>PET</span> BUSINESS</h1>
                            <p>1,500만 이상의 반려인과 함께할 제품과 서비스를 개발합니다. 가장 편리하고 믿을만한 서비스를 위해 매일 고민하고 있으니 기대해주세요!</p>
                        </Container>
                    </Jumbotron>                
                </Col>
                <Col xs="12" sm="6">
                    <Jumbotron fluid className="Business-Content">
                        <Container>
                            <h1><span>GAME</span> BUSINESS</h1>
                            <p>게임을 통해 세상과 소통하고 좋은 게임의 가치를 함께 나누고 싶습니다. 나노컴퍼니는 즐겁고 건강한 게임 문화를 만들어 가겠습니다.</p>                        
                        </Container>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    );
}
import React, {useEffect, useState} from 'react';

function windowSize(WrapperComponent) {
  return function WindowSize(props) {

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
      const globalWindow = typeof window !== 'undefined' && window;
      const {innerWidth: windowWidth} = globalWindow; 
      setWindowWidth(windowWidth);
      const handler = () => {
        setWindowWidth(globalWindow.innerWidth);
      };
      
      globalWindow.addEventListener('resize', handler);

      return () => {
        globalWindow.removeEventListener('resize', handler);
      }
    }, []);
  
    return (<WrapperComponent windowWidth={windowWidth} {...props} />);
  }
  
}

export default windowSize;
import React from "react";

import Layout from "../components/layout";
import MainBanner from '../components/mainBanner';
import Business from '../components/business';
import Production from '../components/production';
import Partner from '../components/partner';
import Recruit from '../components/recruit';
import ContactUs from '../components/contactUs';


import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/style.css';

const IndexPage = () => (
  <Layout>
    <MainBanner />
    <Business />
    <Production />
    <Partner />
    <Recruit />
    <ContactUs />
  </Layout>
);

export default IndexPage;

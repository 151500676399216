import React, {useEffect} from 'react';
import {Container, Jumbotron} from 'react-bootstrap';
import windowSize from './windowSize';

function ContactUs({windowWidth}) {
    useEffect(() => {
        const container = document.getElementById('map'); 
        const options = { 
            center: new window.kakao.maps.LatLng(33.450701, 126.570667), 
            level: 3,
        };

        const map = new window.kakao.maps.Map(container, options); 
        const geocoder = new window.kakao.maps.services.Geocoder();

        geocoder.addressSearch('서울특별시 서초구 강남대로 369', function(result, status) {

            // 정상적으로 검색이 완료됐으면 
             if (status === window.kakao.maps.services.Status.OK) {
        
                const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
        
                // 결과값으로 받은 위치를 마커로 표시합니다
                new window.kakao.maps.Marker({
                    map: map,
                    position: coords
                });
        
                // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
                map.setCenter(coords);
            } 
        });      
    });

    return (
        <Jumbotron fluid className="ContactUs" name="contact">
            <Container>
                <h1>CONTACT US</h1>
                <div id="map" style={getDemenstion(windowWidth)}></div>
                <div className="description">
                    오시는 길: 서울특별시 서초구 강남대로 369(서초동, 에이플러스에셋타워)<br/>
                    사업 제휴 및 문의: kevin@nanocompany.co
                </div>
            </Container>
        </Jumbotron>                
    );
}

const getDemenstion = windowWidth => {
    if (windowWidth >= 576) return {width: `${windowWidth >= 1100 ? 1100 : windowWidth - 150}px`, height: '500px', marginTop: '51px'};

    return {width: `${windowWidth - 30}px`, height: '150px', marginTop: '30px'};
}

export default windowSize(ContactUs);

import React from 'react';
import {Container, Jumbotron, Nav} from 'react-bootstrap';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Recruit() {
    return (
        <Jumbotron fluid className="Recruit" name="Recruit">
            <Container className="RecruitContainer">
                <h1>JOIN THE FUN!</h1>
                <p>우리 함께<br/>만들어가요!</p>
                <Nav.Link href="/recruit/" className="headerText ">
                    <button >채용 바로가기 <span className="margin10"><FontAwesomeIcon icon={faChevronRight} color={"#4eabb9"} /></span></button>                    
                </Nav.Link>
            </Container>
        </Jumbotron>                
    );
}
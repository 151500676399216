import React from 'react';
import {Container, Jumbotron} from 'react-bootstrap';

export default function Partner() {
    return (
        <Jumbotron fluid className="Partner">
            <Container>
                <h1>PARTNER</h1>
                <div>
                    <a href="http://www.hyponic.co.kr" target="_blank" rel="noopener noreferrer" ><img src="/image/partner_koona.png" alt="" /></a>
                    <a href="https://fairplayalliance.org" target="_blank" rel="noopener noreferrer"><img src="/image/partner_FPA.png" alt="" className="notFirst" /></a>
                </div>
            </Container>
        </Jumbotron>                
    );
}
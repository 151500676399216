import React from 'react';
import {Container, Jumbotron, Row, Col} from 'react-bootstrap';

export default function Production() {
    return (
        <Jumbotron fluid className="Production">
            <Container>
                <Row>
                    <Col xs="12" sm="6" >
                        <h1>PRODUCT</h1>
                        <div className="productContent">
                            <img src="/image/app_koona.png" alt="" />
                            <h2>모바일 어플리케이션 플랫폼</h2>
                            <p>‘쿠나’는 소셜 어플리케이션은 강아지와 고양이를 사랑하는 사람들이 모여 함께 즐거움을 나누는 가상 공간입니다.</p>
                            <button onClick={e => {
                                window.open('https://play.google.com/store/apps/details?id=com.koonaent.koonaland.rn', '_blank');
                            }}>
                                <img src="/image/logo_googleplay.png" alt="" />
                            </button>
                        </div>
                    </Col>
                    <Col xs="12" sm="6">
                        <div className="productImage">
                            <img src="/image/img_koona.png" alt="" />
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </Jumbotron>                
    );
}
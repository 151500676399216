import React from 'react';
import {Carousel, Container} from 'react-bootstrap';
import windowSize from './windowSize';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MainBanner({windowWidth}) {
    return (
        <>        
            <div className="MainBanner" style={getImageUrl(windowWidth)}></div>
            <Container className="mainContainer">
                <div className="bannerCaption">
                    <div className="bannerTitleText">
                        재미있는 상상, 즐거운 사람들
                    </div>
                    <button className="bannerButton" onClick={() => {
                        window.location="/company-information/";
                    }}>
                        <span>나노 더보기</span><span className="margin30"><FontAwesomeIcon icon={faChevronRight} color={"#fff"} size="1x" /></span>
                    </button>
                </div>
            </Container>
        </>
    );        
}

const getImageUrl = windowWidth => (
    windowWidth >= 576 ? 
    {
        backgroundImage: 'url(/image/img_event3.png)',
        backgroundPosition: `-${(1919 - windowWidth) / 2}px 0`
    }
    : {
        backgroundImage: 'url(/mobile/img_event2.png)',
        backgroundSize: `${windowWidth}px`,
        backgroundRepeat: 'no-repeat',
    });

export default windowSize(MainBanner);
